import React from "react"
import Modal from "react-modal"
import Slider from "react-slick"
import { Image } from "../Core"

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0 0 0 / 92%)"
  },
  content: {
    padding: "0",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "900px"
  }
}

const settings = {
  dots: true,
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
}

const Gallery = ({ setIsOpen, isOpen, images = [] }) => {
  let el = {}
  if (typeof document !== "undefined") {
    el = document.querySelector("#___gatsby")
  }

  Modal.setAppElement(el)
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      contentLabel="Gallery Modal">
      <div className="gallery">
        <div className="gallery__close" onClick={() => setIsOpen(false)}>
          ✕
        </div>
        <Slider {...settings}>
          {images.map(img => (
            <Image crop="fit" height="500" key={img} publicId={img} />
          ))}
        </Slider>
      </div>
    </Modal>
  )
}

export default Gallery
